import React from "react";
import { Link, Element } from "react-scroll";
import ReactMarkdown from "react-markdown";
import { graphql, useStaticQuery } from "gatsby";

function MarkdownFilesList() {
  const data = useStaticQuery(
    graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "markdown-content" } }) {
        nodes {
          name
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  `);

  const rawData = data.allFile.nodes.map(node => ({
    filename: node.name,
    content: node.childMarkdownRemark?.rawMarkdownBody || "No content",
  }));

  return rawData.sort((a, b) => (a.filename || "").localeCompare(b.filename || ""));
}

const VisaPageContent = () => {
  const markdownData = MarkdownFilesList();

  const formatTitle = (filename) => {
    return filename
      .replace(".md", "")
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col xl:px-32 items-center text-center gap-6 py-20">
          <h1 className="font-display md:text-display-2xl text-display-lg">
            Visa Requirements
          </h1>
          <p className="col-span-8 md:text-body-xl text-body-lg font-light text-neutral-700 max-w-[800px]">
            Every country has their own unique set of requirements for issuing a visa, dependent on the type of visa being applied for.
            The requirements stated here are typically needed for a tourist visa, and are subject to change without prior notice.
          </p>
        </div>
      </div>

        <div className="container mx-auto">
            <div className="flex flex-row gap-6">
                <div className="md:flex hidden flex-row gap-4 items-center">
                    {markdownData.map((file) => {
                        const name = file.filename.replace(".md", "");
                        return (
                            <Link
                                spy={true} 
                                key={name} 
                                to={name} 
                                smooth={true} 
                                duration={500} 
                                className="text-body-lg font-medium text-neutral-700 hover:text-primary-600 px-4 cursor-pointer"
                            >
                                {formatTitle(file.filename)}
                            </Link>
                        );
                    })}
                </div>
            </div>

            <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-15 lg:py-16 py-12 items-center">
                <div className="lg:col-span-12 flex flex-col gap-6">
                    {markdownData.map((file) => {
                    const id = file.filename.replace(".md", "");
                    return (
                        <Element key={id} name={id} id={id} className="prose prose-lg">
                            <ReactMarkdown>{file.content}</ReactMarkdown>
                        </Element>
                    );
                    })}
                </div>
            </div>
        </div>
    </div>
  );
};

export default VisaPageContent;
