import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import VisaPageContent from "../components/visapagecontent";

const visanavigation = [
  { name: "Home", url: "\\"},
  { name: "Visa Requirements", href: "/" },
];
const VisaPage = () => (
  <Layout headerlinks = {visanavigation} >
    <Seo />
    <VisaPageContent />
  </Layout>
);

export default VisaPage;
